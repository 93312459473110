import { Routes, Route, Navigate } from "react-router-dom";
import Casino from "./layouts/casino";
import SignIn from "./layouts/auth/signIn";
import { useEffect } from "react";
import i18n from "./i18n";

const ProtectedRoute = ({ children } : any) => {
  const username = localStorage.getItem("username");
  const casinoCode = localStorage.getItem("casinoCode");
  return (username && casinoCode) ? children : <Navigate to="/auth" replace />;
};

const App = () => {
  useEffect(() => {
    const handleLanguageChange = () => {
      const newLang: string | any = localStorage.getItem('i18nextLng');
      i18n.changeLanguage(newLang);
    };
    window.addEventListener('storage', handleLanguageChange);
    return () => {
      window.removeEventListener('storage', handleLanguageChange);
    };
  }, []);

  if (window.location.pathname === '/exit') {
    localStorage.clear();
  }

  return (
    <Routes>
      <Route path="auth/*" element={<SignIn />} />
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <Casino />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
