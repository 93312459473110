import axiosClient from './axiosClient';
// import { IPassword } from 'views/admin/main/account/settings/components/Password';
import { REACT_APP_API_URL } from '../app-constants';

export const login = async (username: string, password: string) => {
    const url = REACT_APP_API_URL + 'api/login'
    const res = await axiosClient.post(url, {
        username,
        password,
    })
    if (res?.data) {
        if (res.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(res.data));
        }
        return res.data;
    }
};

// export const changePassword = async (params: IPassword) => {
//     const url = REACT_APP_API_URL + '/operator/change-password';
//     const res = await axiosClient.post(url, params)
//     if (res?.data) {
//         return res.data;
//     }
// }
